import ImageComponent from '@components/common/ImageComponent/ImageComponent';
import TitleH2BlockGradient from '@components/common/Title/TitleH2BlockGradient';
import ClockIcon from '@components/icons/ClockIcon';
import DiamondIcon from '@components/icons/DiamondIcon';
import ScaleIcon from '@components/icons/ScaleIcon';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { getPosterURL } from '@invideoio/web-shared/utils/common';
import React, { useEffect, useRef } from 'react';
import { PosterType, ScrollCards } from 'src/types';

import styles from './SwitchBackCards.module.scss';

interface SwitchBackCardsProps {
  data: ScrollCards;
  browser: string;
}

const icons: Record<number, React.FC<any>> = {
  1: ScaleIcon,
  2: DiamondIcon,
  3: ClockIcon,
};

function SwitchBackCards(props: SwitchBackCardsProps) {
  const { data, browser } = props;

  const { ScrollCard, title } = data;

  const stickyRef = useRef<Array<HTMLDivElement | null>>([]);
  const switchRef = useRef<Array<HTMLDivElement | null>>([]);

  const getIcon = (index: number) => {
    const Icon = icons[index + (1 % 3)];

    return (
      <Icon className={ivclass('iv-w-8 iv-h-32 sm:iv-w-5 sm:iv-h-[20px]')} />
    );
  };

  useEffect(() => {
    if (switchRef.current) {
      switchRef.current.forEach((node, index) => {
        const hasIOSupport = !!window.IntersectionObserver;

        if (!hasIOSupport || !node) return;

        const observerParams = { threshold: 0.3 };
        const observer = new IntersectionObserver(([entry]) => {
          const isVisible = !!entry?.isIntersecting;

          if (isVisible) {
            stickyRef.current.forEach(imageNode => {
              if (imageNode) {
                imageNode.classList.remove(styles['visible']);
              }
            });
            stickyRef.current[index]?.classList.add(styles['visible']);
          }
        }, observerParams);

        observer.observe(node);

        return () => observer.disconnect();
      });
    }
  }, [switchRef]);

  useEffect(() => {
    if (stickyRef.current) {
      stickyRef.current[0]?.classList.add(styles['visible']);
    }
  }, [stickyRef]);

  return (
    <div
      className={ivclass(
        'iv-container iv-mx-auto',
        'iv-pt-200 lg:iv-pt-150 md:iv-pt-150 sm:iv-pt-75',
      )}
    >
      <div
        className={ivclass(
          'iv-grid',
          'iv-mb-50 sm:iv-mb-30 md:iv-px-30 sm:iv-px-20',
        )}
      >
        <TitleH2BlockGradient
          heading={title}
          spanClass="iv-block md:iv-inline sm:iv-inline"
        />
      </div>

      <div className={ivclass('iv-relative iv-mx-auto', 'iv-flex')}>
        <div className="iv-flex-1">
          {ScrollCard &&
            ScrollCard.map((card, index) => {
              const videPoster: PosterType = card.media.desktop || {
                defaultURL: card.media.url,
              };

              const posterURL = getPosterURL(videPoster, browser);
              return (
                <div
                  key={card.id}
                  className={ivclass(
                    'iv-h-[70vh] md:iv-h-auto md:iv-w-full sm:iv-h-auto sm:iv-w-full',
                    'iv-flex iv-justify-center iv-items-center md:iv-flex-col sm:iv-flex-col sm:iv-mb-40',
                    'iv-px-20 lg:iv-pl-40 md:iv-py-40',
                  )}
                >
                  <div
                    className={ivclass('iv-flex iv-justify-center iv-max-w-xl')}
                  >
                    <div
                      className={ivclass(
                        'iv-mr-40 sm:iv-mr-20',
                        'iv-w-8 iv-h-32 sm:iv-w-5',
                      )}
                    >
                      {getIcon(index)}
                    </div>
                    <div
                      className={ivclass()}
                      ref={el => (switchRef.current[index] = el)}
                    >
                      <h3
                        className={ivclass(
                          'iv-text-heading-2xs iv-m-heading-2xs iv-font-bold md:iv-text-heading-3xs md:iv-m-heading-3xs sm:iv-text-body-3xl sm:iv-m-body-3xl',
                          'iv-text-grey-85',
                        )}
                      >
                        {card.title}
                      </h3>
                      <div
                        className={ivclass(
                          'iv-text-body-2xl iv-m-body-2xl sm:iv-text-body-l sm:iv-m-body-l',
                          'iv-text-grey-60 iv-font-medium',
                          'iv-pt-20 sm:iv-pt-15',
                        )}
                      >
                        {card.content}
                      </div>
                    </div>
                  </div>

                  <div
                    key={card.id}
                    className={ivclass(
                      'iv-hidden md:iv-flex sm:iv-flex md:iv-justify-start sm:iv-justify-start',
                      'iv-w-full iv-h-full iv-max-w-xl',
                    )}
                  >
                    <ImageComponent
                      elementype="native"
                      src={posterURL}
                      alt="asset"
                      className={ivclass(
                        'iv-top-10 iv-left-0 iv-object-contain iv-object-top iv-h-auto iv-transition-all iv-duration-500 ',
                        'sm:iv-my-40 last-of-type:sm:iv-mb-5 last-of-type:md:iv-mb-10 md:iv-my-40 iv-mx-auto',
                      )}
                      loading="lazy"
                      ref={el => (stickyRef.current[index] = el)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className="iv-flex-1 iv-relative iv-h-auto md:iv-hidden sm:iv-hidden">
          <div
            className={ivclass(
              'iv-flex iv-flex-col iv-items-center',
              'iv-sticky iv-w-full iv-h-[70vh]',
            )}
            style={{
              top: 'calc(50% - 35vh)',
            }}
          >
            {Array.isArray(ScrollCard) &&
              ScrollCard.map((card, index) => {
                const videPoster: PosterType = card.media.desktop || {
                  defaultURL: card.media.url,
                };

                const posterURL = getPosterURL(videPoster, browser);

                return (
                  <div
                    key={card.id}
                    className={ivclass('iv-absolute', 'iv-w-full iv-h-full')}
                  >
                    <ImageComponent
                      elementype="native"
                      src={posterURL}
                      alt="asset"
                      className={ivclass(
                        'iv-absolute iv-left-0 iv-h-auto',
                        'iv-object-contain iv-object-top',
                        'iv-transition-all iv-duration-500',
                        'iv-opacity-0 sm:iv-opacity-1 sm:iv-relative iv-top-0 sm:iv-top-10',
                      )}
                      loading="lazy"
                      ref={el => (stickyRef.current[index] = el)}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SwitchBackCards;
